import React from "react"
import { Container } from "react-bootstrap"
import Formpage from "components/pages/Form"
import HeaderTwo from "components/shared/HeaderTwo"
import Footer from "components/shared/Footer"
import IPTVHero from "components/pages/IPTVHero/index"
import IPTVExperience from "components/pages/IPTVExperience/index"
import IPTVPrices from "components/pages/IPTVPrices"
import { Helmet } from "react-helmet"
const Iptvpage = () => {
	return (
		<>
			<Helmet>
				<html lang="en" />
				<title>MercurySols Technologies | IPTV</title>
				<meta
					name="description"
					content="Mercury Sols offers IPTV middleware and application development services, along with maintenance and support, to help you launch your own OTT IPTV platform. Our experienced team specializes in delivering custom solutions with low bandwidth requirements, to ensure a seamless user experience. Contact us to experience our expertise in IPTV middleware and application development services."
				/>
			</Helmet>
			<HeaderTwo />
			<IPTVHero />
			<IPTVExperience />
			<IPTVPrices />
			<Container>
				<Formpage />
			</Container>
			<Footer />
		</>
	)
}

export default Iptvpage
