import React from "react";
import styles from './styles.scss'
function PlayButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 45 38"
      className="playBtnMain"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M34.861 2.357H9.843a8.34 8.34 0 00-8.34 8.34v16.678a8.34 8.34 0 008.34 8.34h25.018a8.34 8.34 0 008.34-8.34V10.697a8.34 8.34 0 00-8.34-8.34z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M28.606 19.036l-10.424-6.255v12.51l10.424-6.255z"
      ></path>
    </svg>
  );
}

export default PlayButton;