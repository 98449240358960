import React from "react";
import styles from './styles.scss';
function IptvMiddleware() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 50 49"
    >
      <path
        fill="#fff"
        d="M41.723 4.937H17.217c-2.252 0-4.084 1.78-4.084 3.967v23.802c0 2.187 1.832 3.966 4.084 3.966h24.506c2.253 0 4.085-1.779 4.085-3.966V8.904c0-2.188-1.832-3.967-4.085-3.967zM17.217 32.705v-23.8h24.506l.004 23.8h-24.51z"
      ></path>
      <path
        fill="#fff"
        d="M9.047 16.838H4.963v23.801c0 2.188 1.832 3.967 4.084 3.967h24.506V40.64H9.047V16.838z"
      ></path>
      <path
        fill="#fff"
        d="M25.384 24.772l-2.042-1.984-4.084 5.95H39.68l-8.17-11.9-6.126 7.934z"
      ></path>
    </svg>
  );
}

export default IptvMiddleware;