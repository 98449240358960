import React from "react";

function IptvDevelopment() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 43 43"
    >
      <path
        fill="#fff"
        d="M21.665 29.265a8.592 8.592 0 006.394-2.764 1.958 1.958 0 012.764-.114c.791.734.84 1.973.106 2.764-1.786 1.95-4.884 4.029-9.264 4.029-4.379 0-7.478-2.08-9.263-4.029-.734-.79-.759-2.03.106-2.764a1.958 1.958 0 012.764.114 8.592 8.592 0 006.393 2.764zM18.534 19.22l-.016-.025c-.017-.016-.033-.04-.057-.073-.057-.065-.13-.155-.229-.27-.204-.236-.49-.537-.832-.913-.717-.562-1.54-1.068-2.258-1.068s-1.542.506-2.26 1.068c-.342.376-.627.677-.831.914-.098.114-.245.204-.229.269-.097.032-.04.057-.056.073l-.017.025c-.171.228-.465.318-.726.228a.656.656 0 01-.448-.62c0-1.46.546-2.903 1.354-3.98.799-1.06 1.949-1.891 3.213-1.891s2.413.832 3.213 1.892c.807 1.076 1.353 2.52 1.353 3.98 0 .277-.18.53-.448.619-.261.09-.555 0-.726-.228zm13.048 0l-.017-.025c-.016-.016-.032-.04-.057-.073-.057-.065-.13-.155-.228-.27-.204-.236-.49-.537-.832-.913-.718-.562-1.541-1.068-2.259-1.068-.717 0-1.541.506-2.259 1.068-.342.376-.628.677-.831.914-.098.114-.245.204-.229.269-.098.032-.04.057-.057.073l-.016.025a.646.646 0 01-.726.228.656.656 0 01-.448-.62c0-1.46.546-2.903 1.353-3.98.8-1.06 1.95-1.891 3.213-1.891 1.264 0 2.414.832 3.213 1.892.808 1.076 1.354 2.52 1.354 3.98 0 .277-.18.53-.449.619a.646.646 0 01-.725-.228zm10.96 2.218c0 11.53-9.346 20.876-20.877 20.876C10.135 42.313.79 32.968.79 21.437S10.134.56 21.665.56s20.877 9.346 20.877 20.877zM21.665 4.475c-9.37 0-16.962 7.592-16.962 16.962 0 9.37 7.593 16.962 16.962 16.962 9.37 0 16.962-7.592 16.962-16.962 0-9.37-7.592-16.962-16.962-16.962z"
      ></path>
    </svg>
  );
}

export default IptvDevelopment;