import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./styles.scss"

const IPTVHero = () => {
	return (
		<>
			<div className=" iptvHeroMain mt-5 pt-3">
				<div className="heroimgdiv">
					<StaticImage
						src="../../../assets/images/IptvHero.jpg"
						alt="Website Development Company"
						placeholder="none"
						className="imgHeight"
					></StaticImage>
				</div>
				<div className="iptvOverlay"></div>
				<div className="iptvEntertainment">
					<h1 className="mercuryCompTxt">
						<span className="mercuryFirstTxt">MERCURYSOLS</span> TECHNOLOGIES PVT. LTD.
					</h1>
					<p className="mercuryCompPara">
						Entertaiment is a never-ending industry and television is one of the majorly used entertainment platforms.
						The innovative has paved its way in this platform in a form of IPTV.
					</p>
				</div>
			</div>
		</>
	)
}
export default IPTVHero
