import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./styles.scss"
import IptvMiddleware from "assets/svgs/iptvMiddleware"
import IptvApplication from "assets/svgs/iptvApplication"
import IptvDevelopment from "assets/svgs/iptvDevelopment"
import PlayButton from "assets/svgs/playButton"
import { Col, Container } from "react-bootstrap"

const IPTVExperience = () => {
	return (
		<>
			<div className="iptvExpMain">
				<div className="iptvExpOuter">
					<div className="expHead">
						<h2 className="headTxt">Next Generation TV Experience</h2>
						<span className="headLine"></span>
					</div>
					<div className="expBody">
						<Col lg={3} md={4} sm={6} xs={12}>
							<div className="expCard">
								<div className="iconCont">
									<IptvMiddleware />
								</div>
								<div className="expCardHead">IPTV MIDDLEWARE DEVELOPMENT</div>
								<div className="expCardDesc">
									The information should be not only well-organized and easy to read. thus making the process enjoyable.
								</div>
							</div>
						</Col>
						<Col lg={3} md={4} sm={6} xs={12}>
							<div className="expCard">
								<div className="iconCont">
									<IptvApplication />
								</div>
								<div className="expCardHead">PROXIMA IPTV APPLICATION</div>
								<div className="expCardDesc">
									The information should be not only well-organized and easy to read. thus making the process enjoyable.
								</div>
							</div>
						</Col>
						<Col lg={3} md={4} sm={6} xs={12}>
							<div className="expCard">
								<div className="iconCont">
									<PlayButton />
								</div>
								<div className="expCardHead">MAINTENANCE AND SUPPORT</div>
								<div className="expCardDesc">
									The information should be not only well-organized and easy to read. thus making the process enjoyable.
								</div>
							</div>
						</Col>
						<Col lg={3} md={4} sm={6} xs={12}>
							<div className="expCard">
								<div className="iconCont">
									<IptvDevelopment />
								</div>
								<div className="expCardHead">IPTV APPLICATION DEVELOPMENT</div>
								<div className="expCardDesc">
									The information should be not only well-organized and easy to read. thus making the process enjoyable.
								</div>
							</div>
						</Col>
					</div>
				</div>
			</div>

			<Container className="my-5 py-5">
				<div className="iptvExpMainOTP">
					{" "}
					{/* update add row in line 92 and col in line 93 and 99 */}
					<Col md={6} xs={12}>
						<div className="otpDesc">
							<h2 className="otpDescHead">
								<span className="otpDescHeadSpc">Launch your</span> own OTT IPTV platform, like Disney+,{" "}
								<span className="otpDescHeadSpc">NETFLIX</span>
							</h2>
							<p className="otpDescTxt">
								Our experienced IPTV and OTT developers can offer you the solution which will use the minimum bit-rate
								so you can serve maximum users with the lowest possible bandwidth requirements.
							</p>
						</div>
					</Col>
					<Col md={6} xs={12}>
						<div className="otpImage">
							<StaticImage src="../../../assets/svgs/iptv2.svg" alt="Website IT Company" placeholder="none"></StaticImage>
						</div>
					</Col>
				</div>
			</Container>
		</>
	)
}
export default IPTVExperience
