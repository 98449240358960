import React from "react";

function IptvApplication() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 51 49"
    >
      <path
        fill="#fff"
        d="M3.423 5.478c0-1.268.496-2.483 1.38-3.38A4.683 4.683 0 018.139.7h34.577c1.25 0 2.45.504 3.334 1.4a4.811 4.811 0 011.38 3.379v22.3a4.811 4.811 0 01-1.38 3.38 4.683 4.683 0 01-3.334 1.4H25.426v-3.186h17.289c.416 0 .816-.168 1.111-.467.295-.299.46-.704.46-1.126V5.478c0-.423-.165-.828-.46-1.127a1.561 1.561 0 00-1.111-.466H8.138c-.417 0-.817.168-1.112.466-.294.3-.46.704-.46 1.127v4.779H3.423v-4.78zm22.003 33.451h23.575c.417 0 .817-.168 1.112-.466a1.604 1.604 0 000-2.253A1.561 1.561 0 0049 35.743H25.426v3.186zm-15.717 0c-.416 0-.816.168-1.111.467a1.604 1.604 0 000 2.252c.295.3.695.467 1.111.467h3.144c.417 0 .816-.168 1.111-.467a1.604 1.604 0 000-2.252 1.561 1.561 0 00-1.111-.467H9.709zM.28 18.221c0-1.267.497-2.483 1.381-3.379a4.683 4.683 0 013.334-1.4h12.574c1.25 0 2.45.504 3.334 1.4a4.811 4.811 0 011.38 3.38v25.486a4.811 4.811 0 01-1.38 3.379 4.683 4.683 0 01-3.334 1.4H4.994c-1.25 0-2.45-.504-3.334-1.4a4.812 4.812 0 01-1.38-3.38V18.222zm4.715-1.593a1.56 1.56 0 00-1.111.467c-.295.299-.46.704-.46 1.126v25.487c0 .422.165.828.46 1.126.295.299.695.467 1.111.467h12.574c.417 0 .816-.168 1.111-.467.295-.298.46-.704.46-1.126V18.22c0-.422-.165-.827-.46-1.126a1.56 1.56 0 00-1.111-.467H4.994z"
      ></path>
    </svg>
  );
}

export default IptvApplication;