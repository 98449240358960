import React from "react"
import { StaticImage } from "gatsby-plugin-image"
// import IptvHeroSvg from 'assets/svgs/iptvHeroSvg';
import "./styles.scss"
import { Accordion, Col, Container, Row } from "react-bootstrap"
import AOS from "aos"

const IPTVPrices = () => {
	if (typeof window !== "undefined") {
		window.addEventListener("load", AOS.refresh())
		AOS.init({
			once: true,
			mirror: true,
		})
	}
	return (
		<>
			<div className="iptvPricesCard">
				{/* ------------------iptv  services ---------------------- */}
				<Container>
					<Row className="mt-5 pt-5">
						<Col md={7} xs={12} className="one" data-aos="fade-right">
							<StaticImage
								placeholder="none"
								src="../../../assets/images/iptvSoftwareDevices.png"
								alt="Website IT Company"
								style={{ marginTop: "40px", alignItems: "center" }}
							></StaticImage>
						</Col>
						<Col md={5} xs={12}>
							<p className="price " style={{ textAlign: "Start" }}>
								IPTV Service
							</p>
							<Accordion style={{ boxShadow: "0 2px 10px 3px rgb(0 0 0 / 10%)" }}>
								<Accordion.Item eventKey="0">
									<Accordion.Header className="offerss onFocus fade-in">
										{" "}
										<span style={{ fontSize: "1.8rem" }}>Mobile App</span>
									</Accordion.Header>
									<Accordion.Body className="fade-in fadeInDown">Write Mobile App Detail Here</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1" className="fade-in">
									<Accordion.Header className="offerss fade-in ">
										<span style={{ fontSize: "1.8rem" }}>Kodi Plugins</span>
									</Accordion.Header>
									<Accordion.Body className="fade-in fadeInDown">Write Kodi Plugins Detail Here.</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2">
									<Accordion.Header className="offerss ">
										<span style={{ fontSize: "1.8rem" }}>Server Configurations</span>
									</Accordion.Header>
									<Accordion.Body className="fade-in fadeInDown">
										Write Server Configurations Detail Here.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="3">
									<Accordion.Header className="offerss ">
										<span style={{ fontSize: "1.8rem" }}>Web App</span>
									</Accordion.Header>
									<Accordion.Body className="fade-in fadeInDown">Write Web App Detail Here.</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}
export default IPTVPrices
